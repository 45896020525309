<template>
  <div class="bbc">
    <div style="background-color:white" class="d-flex justify-content-between justify-space-between">
      <router-link to="login" style="padding:10px;">
        <img src="../../static/img/logo-full.png" alt="logo" style="margin-left: 25px;" />
      </router-link>
      <!-- <div> -->
      <div class="text-grey-dim font-bold relative help">
        <a class="cursor-pointer hover:text-primary" @click="openDropDown" title="Need Help"
          ><strong><img src="../../static/img/help.png" style="height:2rem"/></strong
        ></a>
        <HelpDropDown v-if="showDropDown" @close="showDropDown = false" />
      </div>
      <!-- </div> -->
    </div>
    <AuthWrapper>
      <div class="auth-contents">
        <a-form @finish="handleSubmit" :model="formState" class="white-box" layout="vertical">
          <sdHeading as="h3">Forgot Password?</sdHeading>
          <p class="forgot-text">
           Enter the email address associated with your Better Doctor account if an account exists we’ll send you instructions on how to reset your password.
          </p>
          <a-form-item label="Email Address" name="email">
            <a-input type="email" v-model:value="formState.email" placeholder="name@example.com" required />

            <!-- <input type="radio" v-model="pick" v-bind:value="a" /> -->
          </a-form-item>
          <a-form-item>
            <sdButton class="btn-reset" style="background-color: #1BABA8" htmlType="submit" type="primary" size="large">
              <!-- {{ formState.passreset ? 'Sending...' : 'Send' }} -->
              <div v-if="!formState.passreset">Send</div>
              <a-spin v-if="formState.passreset" color="white" size="large" />
            </sdButton>
          </a-form-item>
          <a-row>
            <!-- <a-col :xl='16' > -->
            <p class="return-text" style="width:100%">
              <span style="float:left"><router-link to="/auth/login">Return to Sign In</router-link></span>
              <!-- <span style="float:right"> <router-link to="/auth/otp">Reset Using SMS</router-link></span> -->
            </p>

            <!-- </a-col> -->
            <!-- <a-col :xl='8' class="resetbutton"> -->
            <!-- <p class="return-text" ></p> -->

            <!-- </a-col> -->
          </a-row>
        </a-form>
      </div>
    </AuthWrapper>
  </div>
</template>
<script>
import { reactive } from 'vue';
import { AuthWrapper } from './style';
import axios from 'axios';
import { Notification } from 'ant-design-vue';
import HelpDropDown from './HelpDropDown.vue';

const ForgotPassword = {
  data() {
    return {
      showDropDown: false,
      // loader: false
    };
  },
  methods: {
    openDropDown() {
      console.log('-----');
      console.log(this.showDropDown);
      this.showDropDown = true;
      console.log('-----');
    },
    openNotificationIcon() {
      Notification.open({
        message: 'Notification Title',
        description:
          'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
        icon: <sdFeatherIcons type="smile" size="14" style="color: #108ee9" />,
      });
    },
  },
  name: 'ForgotPassword',
  components: { AuthWrapper, HelpDropDown },
  setup() {
    const handleSubmit = values => {
      formState.passreset = true;
      axios
        // .post('https://api.getbetterdoctor.com/api/ForgetPassword', { email: formState.email })
        .post('post-forgetpassword.php', { email: formState.email })
        .then(res => { 
          let response = res.data;
          console.log(response);

          if (response.status === false) {
            formState.passreset = false;
            Notification.open({
              message: 'Password Reset Fail',
              description: 'Email Not Found',
              icon: <sdFeatherIcons type="smile" size="14" style="background-color: #1BABA8" />,
            });

            console.log('Email Not Found');
          }

          if (response.status === true) {
            formState.passreset = false;

            Notification.open({
              message: 'Password Reset',
              description: 'Password Reset Mail Sent',
              icon: <sdFeatherIcons type="smile" size="14" style="background-color: #1BABA8" />,
            });
            console.log('Password Reset Mail Sent');
          }
        })
        .catch(err => {
          console.log('err');

          console.log(err);
        });

      // router.push('/');
      //  dispatch('login');
    };

    const formState = reactive({
      email: '',
      passreset: false,
    });

    return {
      handleSubmit,
      formState,
    };
  },
};

export default ForgotPassword;
</script>
<style scoped>
.bbc {
  background-image: url('../../static/img/background.png');
  /* background-image: url('https://pngimage.net/wp-content/uploads/2018/06/png-background-images-1-1.png'); */
  height: 100vh;
  /* height: 920px; */
  background-color: #f4f5f7;
  display: block;
}

.lavNKW .auth-contents form {
  margin-top: 80px;
  width: 599px;
}
.lavNKW .auth-contents form h1 {
  font-size: 56px;
  font-weight: 600;
  margin-bottom: 45px;
}
/* .label {
    height: auto;
    font-size: 92px;
} */
.ant-form-vertical .ant-form-item-label > label {
  height: auto;
  font-size: 90px;
}
.lavNKW .auth-contents form .ant-input {
  padding: 12px 11px;
  height: 45px;
  width: 599px;
}

@media (min-width: 1024px) and (max-width: 1700px) {
  /* For desktop: */

  .lavNKW .auth-contents form {
    margin-top: 80px;
    width: 599px;
  }
  .lavNKW .auth-contents form h1 {
    font-size: 56px;
    font-weight: 600;
    margin-bottom: 45px;
  }
  /* .label {
    height: auto;
    font-size: 92px;
} */
  .ant-form-vertical .ant-form-item-label > label {
    height: auto;
    font-size: 90px;
  }
  .lavNKW .auth-contents form .ant-input {
    padding: 12px 11px;
    height: 45px;
    width: 599px;
  }
}

.white-box {
  border-radius: 5px;
  background-color: white;
  padding: 20px;
}
.help {
  /* margin-inline: 500px; */
  /* margin-right: 900px; */
  /* float:right;
   padding: 1%; */
  position: relative;
  margin-right: 26px;
  color: gray;
  align-items: center;
  display: flex;
}
.help a,
a:visited,
a:hover {
  color: gray;
}
</style>
<style>
.ant-spin-dot-item {
  background-color: white !important;
}
</style>
